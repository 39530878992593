import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { Container } from 'reactstrap'
import BlockHeader from '../components/block-header'
import BlockJumpLinks from '../components/block-jump-links'
import BlockTitle from '../components/block-title'
import BlockImage from '../components/block-image'
import BlockText from '../components/block-text'
import BlockRegisterInterest from '../components/block-register-interest'
import BlockVideo from '../components/block-video'
import BlockRowBreak from '../components/block-row-break'
import BlockAccordion from '../components/block-accordion'
import BlockBackground from '../components/block-bg'
import BlockIconAndText from '../components/block-icon-and-text'
import BlockNumberAndText from '../components/block-number-and-text'
import BlockNeedMoreHelpFooter from '../components/block-need-more-help-footer'
import SchemeCheckerIndex from '../pages/scheme-checker'
import BlockIframe from '../components/block-iframe'
import BlockHomePageBanner from '../components/block-home-page-banner'
import BlockHomePageBoxes from '../components/block-home-page-boxes'
import BlockIncident from '../components/block-incident'
import BlockTecDocs from '../components/block-tech-docs'

class ComponentPage extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      components: props.pageContext.components,
    }
  }

  render() {
    console.log(this.state.components)

    const siteTitle = this.props.data.site.siteMetadata.title
    const pageTitle = this.props.pageContext.title
    const metaDescription = this.props.pageContext.description
    let useChildren = false
    let rowOpen = false
    let children = []
    let fields = null
    let b;

    let rowChildren = []
    return (
      <>
        <Helmet title={pageTitle + ' | ' + siteTitle}>
          <meta name="description" content={metaDescription} />
          <meta property="og:url" content={this.props.location.href} />
          <meta property="og:type" content={'website'} />
          <meta property="og:title" content={pageTitle + ' | ' + siteTitle} />
          <meta property="og:description" content={metaDescription} />
          {this.props.pageContext.noIndex && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <Layout hideNewsLetter location={this.props.location}>
          <div className="component-holder" style={{ background: '#fff' }}>
            {this.state.components.map((c, i) => {
              // if draft component
              if (!c.sys.contentType) {
                return
              }
              switch (c.sys.contentType.sys.id) {
                case 'headerBlock':
                  b = <BlockHeader key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentJumpLinks':
                  b = <BlockJumpLinks key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b

                case 'componentTitle':
                  b = <BlockTitle key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentImage':
                  b = <BlockImage key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentText':
                  b = <BlockText key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentVideo':
                  b = <BlockVideo key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentAccordion':
                  b = <BlockAccordion key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentRowBreak':
                  b = <BlockRowBreak key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b

                case 'componentStartBackgroundColour':
                  useChildren = true
                  fields = c.fields
                  return

                case 'componentEndBackgroundColour':
                  useChildren = false

                  const childs = children
                  children = []

                  const f = fields
                  fields = null

                  return (
                    <BlockBackground
                      key={`component${i}`}
                      data={f}
                      children={childs}
                    />
                  )

                case 'componentRegisterYourInterest':
                  b = (
                    <BlockRegisterInterest
                      key={`component${i}`}
                      data={c.fields}
                    />
                  )
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b

                case 'componentIconAndText':
                  b = <BlockIconAndText key={`component${i}`} data={c.fields} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b

                case 'componentNumberAndText':
                  b = (
                    <BlockNumberAndText key={`component${i}`} data={c.fields} />
                  )
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b

                case 'componentNeedMoreHelpFooter':
                  b = (
                    <BlockNeedMoreHelpFooter
                      key={`component${i}`}
                      data={c.fields}
                    />
                  )
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentWizard':
                  b = (
                    <div style={{ width: '100%' }} id="checker">
                      <SchemeCheckerIndex key={`component${i}`} />
                    </div>
                  )
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentIframe':
                  b = <BlockIframe data={c.fields} key={`component${i}`} />
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentHomePageBanner':
                  b = (
                    <div style={{ width: '100%' }} id="banner">
                      <BlockHomePageBanner
                        data={c.fields}
                        key={`component${i}`}
                      />
                    </div>
                  )
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'blockHomePageBoxes':
                  b = (
                    <div style={{ width: '100%' }} id="boxes">
                      <BlockHomePageBoxes
                        data={c.fields}
                        key={`component${i}`}
                      />
                    </div>
                  )
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                case 'componentIncidentBanner':
                  b = (
                    <div style={{ width: '100%' }}>
                      <BlockIncident
                        data={c.fields}
                        key={`component${i}`}
                      />
                    </div>
                  )
                  if (useChildren) {
                    children.push(b)
                    return
                  }
                  return b
                  case 'componentTechnicalDocumentSection':
                    b = <BlockTecDocs key={`component${i}`} data={c.fields} />
                    if (useChildren) {
                      children.push(b)
                      return
                    }
                    return b

                default:
                  break
              }
            })}
          </div>
        </Layout>
      </>
    )
  }
}

export default ComponentPage

export const pageQuery = graphql`
  query ComponentPageBySlug {
    site {
      siteMetadata {
        title
      }
    }
  }
`
